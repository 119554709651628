import * as React from 'react';
import { View, StyleSheet, ActivityIndicator, Text, useState, StatusBar } from 'react-native';
import { MainDrawer } from './navigation/MainDrawer';
import { Helmet } from 'react-helmet';

function Main() {

  return (
    <View style={styles.container}>
      <Helmet>
        <title>Amontola - Richmond | Order Direct and Save</title>
        <meta name="application-name" content="Amontola" />
        <meta name="description" content="Order delicious Indian food online with Amontola and enjoy exclusive savings. Freshly prepared dishes available for fast delivery in Richmond or collection." />
        <meta name="keywords" content="React Native Web, SEO, Example" />
        <meta property="og:title" content="Amontola - Richmond | Order Direct and Save" />
        <meta property="og:description" content="Order delicious Indian food online with Amontola and enjoy exclusive savings. Freshly prepared dishes available for fast collection in Richmond" />
        <meta property="og:url" content="https://order.amontola.co.uk" />
      </Helmet>
      <StatusBar barStyle="light-content" translucent={true} />
      <MainDrawer />
    </View>
  )
}


export default Main;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row"
  },
});
