// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyBocrP0EU8l5PxY84A0Cv0tMPWSPxvG98E",
  authDomain: "fyre-amontola.firebaseapp.com",
  projectId: "fyre-amontola",
  storageBucket: "fyre-amontola.appspot.com",
  messagingSenderId: "546298019238",
  appId: "1:546298019238:web:0abdf0ca66181c1f4b2367",
  measurementId: "G-NE6927V37R"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyC-DsWeMrcWpnLHcWdSXKuJ2vtJbA5dLGs",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:3c2cbbff655c8547e8dec1",
  measurementId: "G-KLRDX1529N"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
